import { postRequest, getRequest, deleteRequest } from '../utils/request'
export default {
    getlistAlliance(){
        return getRequest(`/sys/user/getlistAlliance`)
    },
    getListMerchants(alId){
        return getRequest(`/sys/user/getListMerchants?meAlId=${alId}`)
    },
    getListSyStore(meId,alId){
        return getRequest(`/sys/user/getListSyStore?soeMeId=${meId}&alId=${alId}`)
    },
    getListStore(){
        return getRequest(`/sys/user/getListStore`)
    },
    getListRole(){
        return getRequest(`/sys/role/getListRole`)
    },
    getListUserTree(){
        return getRequest(`/sys/user/getListUserTree`)
    },
    getUserTree(){
        return postRequest(`/sys/user/getUserTree`)
    }
}