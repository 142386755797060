<template>
  <div class="app-container">
    <el-row :gutter="30">
      <el-col
        :span="5"
        style="
          min-height: 300px;
          overflow-y: auto;
          padding-top: 15px;
          box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
        "
      >
        <div class="head-container tree-container user-left-tree">
          <el-tree
            :data="treeData"
            :expand-on-click-node="false"
            ref="tree"
            class="tree tree-line"
            accordion
            :indent="0"
            font-size="50"
            @node-click="handleNodeClick"
            default-expand-all
          >
            <span
              class="custom-tree-node"
              slot-scope="{ node, data }"
              @mouseenter="mouseenter(data)"
              @mouseleave="mouseleave(data)"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="node.label"
                placement="top"
              >
                <span style="font-size: 14px">{{ node.label }}</span>
              </el-tooltip>
            </span>
          </el-tree>
        </div>
      </el-col>
      <!--服务实例-->
      <el-col :span="19">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model="searchForm.username"
              placeholder="用户名"
              size="medium"
              clearable
              @keyup.enter.native="search"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              @click="search"
              type="primary"
              icon="el-icon-search"
              size="small"
              v-if="hasAuth('sys:users:select')"
              >搜索</el-button
            >

            <el-button @click="refresh" icon="el-icon-refresh" size="small"
              >重置</el-button
            >
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              @click="addBtn"
              size="small"
              :disabled="btndisable"
              v-if="hasAuth('sys:user:delete')"
              >新增</el-button
            >
          </el-form-item>
        </el-form>

        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          size="small"
          border
          stripe
          :row-style="{ height: '10px' }"
          :cell-style="{ padding: '1px 0' }"
        >
          <el-table-column prop="username" label="用户名" width="120" align="center">
          </el-table-column>

          <el-table-column prop="code" label="角色名称" width="120" align="center">
            <template slot-scope="scope">
              <el-tag size="small" v-for="item in scope.row.sysRoles" :key="item.roleId"
                >{{ item.rname }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column prop="phone" label="手机号" align="center"> </el-table-column>

          <el-table-column prop="alIdName" label="所属商盟" align="center">
          </el-table-column>

          <el-table-column prop="meIdName" label="所属商户" align="center">
          </el-table-column>

          <el-table-column prop="soeIdName" label="所属门店" align="center">
          </el-table-column>

          <el-table-column prop="state" label="用户级别" align="center">
            <template slot-scope="scope">
              <el-tag size="small" v-if="scope.row.level === 0" type="success"
                >平台</el-tag
              >

              <el-tag size="small" v-else-if="scope.row.level === 1" type="danger"
                >商盟</el-tag
              >

              <el-tag size="small" v-else-if="scope.row.level === 2" type="danger"
                >商户</el-tag
              >

              <el-tag size="small" v-else-if="scope.row.level === 3" type="danger"
                >门店</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column prop="icon" width="260px" label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="roleHandle(scope.row)"
                v-if="hasAuth('sys:user:permRole')"
                >分配角色</el-button
              >
              <el-divider direction="vertical" v-if="hasAuth('sys:user:permRole')">
              </el-divider>

              <el-button
                type="text"
                @click="repassHandle(scope.row.userId, scope.row.username)"
                v-if="hasAuth('sys:user:repass')"
                >重置密码</el-button
              >
              <el-divider direction="vertical" v-if="scope.row.level != ranks">
              </el-divider>

              <el-button
                type="text"
                @click="editHandle(scope.row)"
                v-if="hasAuth('sys:user:edit') && scope.row.level != ranks"
                >编辑</el-button
              >
              <el-divider direction="vertical" v-if="scope.row.level != ranks">
              </el-divider>

              <template>
                <span>
                  <span v-if="scope.row.sysRoles && scope.row.sysRoles.length > 0">
                    <el-tooltip
                      v-if="scope.row.level != ranks"
                      class="item"
                      effect="dark"
                      content="请先删除该用户的角色"
                      placement="top"
                    >
                      <span>
                        <el-button type="text" disabled>删除</el-button>
                      </span>
                    </el-tooltip>
                  </span>
                  <span v-else>
                    <el-popconfirm
                      title="确认删除当前选中数据？"
                      @confirm="delHandle(scope.row.userId, scope.row.username)"
                      v-if="scope.row.level != ranks"
                    >
                      <el-button type="text" slot="reference">删除</el-button>
                    </el-popconfirm>
                  </span>
                </span>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10]"
          :current-page="current"
          :page-size="size"
          :total="total"
        >
        </el-pagination>
        <!--新增对话框-->
        <el-dialog
          :title="titleName"
          :visible.sync="dialogVisible"
          width="600px"
          :before-close="handleClose"
          :close-on-click-modal="false"
        >
          <el-form :model="editForm" :rules="editFormRules" ref="editForm">
            <el-form-item label="姓名" prop="name" label-width="100px">
              <el-input v-model="editForm.name" autocomplete="off" :disabled="flag">
              </el-input>
            </el-form-item>
            <el-form-item label="登录名" prop="username" label-width="100px">
              <el-input
                v-model="editForm.username"
                autocomplete="off"
                :disabled="flag"
                :maxlength="8"
                show-word-limit
              >
              </el-input>
              <el-alert
                title="初始密码为888888"
                :closable="false"
                type="info"
                style="line-height: 12px"
              ></el-alert>
            </el-form-item>

            <el-form-item label="手机号" prop="phone" label-width="100px">
              <el-input v-model="editForm.phone" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="所属联盟" prop="alId" label-width="100px">
              <el-select
                v-model="editForm.alId"
                placeholder="请选择"
                @change="allianceChange(editForm.alId)"
                :disabled="alidisable"
              >
                <el-option
                  v-for="item in alIdOptions"
                  :key="item.al_id"
                  :label="item.al_name"
                  :value="item.al_id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="所属商户" prop="meId" label-width="100px">
              <el-select
                v-model="editForm.meId"
                placeholder="请选择"
                @change="merchantsChange(editForm.meId)"
                :disabled="merdisable"
              >
                <!--<el-option label="请选择" :value="0">&#45;&#45;请选择&#45;&#45;</el-option>-->
                <el-option
                  v-for="item in meIdOptions"
                  :key="item.me_id"
                  :label="item.me_name"
                  :value="item.me_id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="所属门店"
              prop="soeId"
              v-if="this.userSoeId !== null"
              label-width="100px"
            >
              <el-select
                v-model="editForm.soeId"
                placeholder="请选择"
                :disabled="soedisable"
              >
                <!--<el-option label="请选择" :value="0">&#45;&#45;请选择&#45;&#45;</el-option>-->
                <el-option
                  v-for="item in soeIdOptions"
                  :key="item.soe_id"
                  :label="item.soe_name"
                  :value="item.soe_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="性别" prop="sex" label-width="100px">
              <el-radio-group v-model="editForm.sex">
                <el-radio :label="0">男</el-radio>
                <el-radio :label="1">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="状态" prop="state" label-width="100px">
              <el-radio-group v-model="editForm.state">
                <el-radio :label="1">正常</el-radio>
                <el-radio :label="0">禁用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="resetForm('editForm')">取 消</el-button>
            <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 分配权限对话框 -->
        <el-dialog
          :title="titleRole"
          :visible.sync="roleDialogFormVisible"
          width="600px"
          :close-on-click-modal="false"
        >
          <el-form :model="roleForm">
            <el-tree
              :data="roleTreeData"
              show-checkbox
              ref="roleTree"
              :check-strictly="checkStrictly"
              node-key="roleId"
              :default-expand-all="true"
              :props="defaultProps"
              @check-change="handleCheckChange"
            ></el-tree>
          </el-form>

          <div slot="footer" class="dialog-footer">
            <el-button @click="roleDialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitRoleHandle('roleForm')"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import api from "@/api/user";
import sys from "@/api/sysUser";

export default {
  name: "Users",
  methods: {
    mouseenter(data) {
      this.$set(data, "show", true);
    },
    mouseleave(data) {
      this.$set(data, "show", false);
    },
    addBtn() {
      this.dialogVisible = true;
    },
    async getUserInfo() {
      await api.userinfo().then((res) => {
        if (res.data.data.result.ranks === 2) {
          let alId = res.data.data.result.alId;
          let meId = res.data.data.result.meId;
          this.editForm.alId = res.data.data.result.alId;
          this.allianceChange(alId);
          this.merchantsChange(meId);
        }
        if (res.data.data.result.ranks == 1) {
          let alId = res.data.data.result.alId;
          this.allianceChange(alId);
        }
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    search() {
      this.current = 1;
      this.isTrue = false;
      this.getUserList();
    },
    handleCheckChange(data, checked, indeterminate) {
      if (checked) {
        this.$refs.roleTree.setCheckedKeys([]);
        this.$refs.roleTree.setCheckedNodes([data]);
      }
    },
    getUserList() {
      let params = {
        username: this.searchForm.username,
        current: this.current,
        typeid: this.typeid,
        searchId: this.searchId,
        istrue: this.isTrue,
      };
      api.list({ params }).then((res) => {
        this.tableData = res.data.data.result.records;
        this.size = res.data.data.result.size;
        this.current = res.data.data.result.current;
        this.total = res.data.data.result.total;
      });
    },
    getlistAlliance() {
      sys.getlistAlliance().then((res) => {
        this.alIdOptions = res.data.data.result;
      });
    },
    merchantsChange(meId) {
      sys.getListSyStore(meId).then((res) => {
        console.log(res)
        this.soeIdOptions = res.data.data.result;
        this.store = "";
        console.log(this.soeIdOptions)
      });
    },
    allianceChange(alId) {
      sys.getListMerchants(alId).then((res) => {
        this.meIdOptions = res.data.data.result;
        if (res.data.data.result.length === 1) {
          this.merchants = res.data.data.result[0].me_id;
        }
        this.store = "";
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api
            .updateUsers(
              this.editForm.userId ? "update" : "save",
              this.editForm,
              this.editForm.meId,
              this.editForm.soeId
            )
            .then((res) => {
              this.$notify({
                title: "信息提示",
                message: "操作成功",
                type: "success",
                position: "bottom-right",
              });
              this.getUserList();
              this.dialogVisible = false;
              this.flag = false;

              if (this.ranks === 0) {
                this.editForm.soeId = "";
                this.editForm.alId = "";
                this.editForm.meId = "";
              } else if (this.ranks === 1) {
                this.editForm.soeId = "";
                this.editForm.meId = "";
              } else if (this.ranks === 2) {
                this.editForm.soeId = "";
              }

              this.editForm.userId = "";
              this.editForm.username = "";
              this.editForm.state = 1;
              this.editForm.sex = 0;
              this.editForm.phone = "";
              this.editForm.name = "";
              this.merchants = "";
              this.store = "";
              this.titleName = "新增用户";
              this.btndisable = true;
            });
        } else {
          return false;
        }
      });
    },
    handleSizeChange(val) {
      this.size = val;
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getUserList();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
      this.flag = false;
      this.merchants = "";
      this.store = "";
      this.titleName = "新增用户";

      if (this.ranks === 0) {
        this.editForm.soeId = "";
        this.editForm.alId = "";
        this.editForm.meId = "";
      } else if (this.ranks === 1) {
        this.editForm.soeId = "";
        this.editForm.meId = "";
      } else if (this.ranks === 2) {
        this.editForm.soeId = "";
      }

      this.editForm.userId = "";
      this.editForm.username = "";
      this.editForm.state = 1;
      this.editForm.sex = 0;
      this.editForm.phone = "";
      this.btndisable = true;
    },

    handleNodeClick(data) {
      this.btndisable = false;
      this.searchId = data.id;
      this.typeid = data.typeid;
      this.isTrue = false;
      this.current = 1;

      if (data.typeid === "3") {
        api.checkinfos(data.typeid, data.id).then((res) => {
          this.editForm.alId = res.data.data.alId;
          this.editForm.meId = res.data.data.meId;
          this.editForm.soeId = res.data.data.soeId;
          this.userSoeId = "";
          this.alidisable = true;
          this.merdisable = true;
          this.soedisable = true;

          this.merchantsChange(this.editForm.meId);
          this.allianceChange(this.editForm.alId);
        });
      } else if (data.typeid === "2") {
        api.checkinfos(data.typeid, data.id).then((res) => {
          this.editForm.alId = res.data.data.alId;
          this.editForm.meId = res.data.data.meId;
          this.userSoeId = null;
          this.alidisable = true;
          this.merdisable = true;
          this.soedisable = false;

          this.merchantsChange(this.editForm.meId);
          this.allianceChange(this.editForm.alId);
        });
      } else if (data.typeid === "1") {
        api.checkinfos(data.typeid, data.id).then((res) => {
          this.editForm.alId = res.data.data.alId;
          this.userSoeId = null;
          this.alidisable = true;
          this.merdisable = false;
          this.soedisable = false;

          this.allianceChange(this.editForm.alId);
        });
      } else if (data.typeid === "0") {
        this.alidisable = false;
        this.merdisable = false;
        this.soedisable = false;

        this.editForm.soeId = "";
        this.editForm.alId = "";
        this.editForm.meId = "";
      }
      this.getUserList();
    },
    async roleHandle(row) {
      let id = row.userId;
      let level = row.level;
      if (level === 1) {
        this.titleRole = `给用户：${row.username}分配商盟级角色`;
      } else if (level === 2) {
        this.titleRole = `给用户：${row.username}分配商户级角色`;
      } else if (level === 3) {
        this.titleRole = `给用户：${row.username}分配门店级角色`;
      }
      this.roleDialogFormVisible = true;
      await api.listRole(row.alIdName, row.meIdName, row.soeIdName).then((res) => {
        this.roleTreeData = res.data.data.result.records;
      });
      await api.getOneUserInfo(id).then((res) => {
        this.roleForm = res.data.data.result;
        // this.titleRole = this.roleForm.name + "-分配角色";
        let roleIds = [];
        res.data.data.result.sysRoles.forEach((row) => {
          roleIds.push(row.roleId);
        });

        this.$refs.roleTree.setCheckedKeys(roleIds);
      });
    },
    submitRoleHandle(formName) {
      var roleIds = this.$refs.roleTree.getCheckedKeys();
      api.saveOrUpdates(this.roleForm.userId, roleIds).then((res) => {
        this.$notify({
          title: "信息提示",
          message: "操作成功",
          type: "success",
          position: "bottom-right",
        });
        this.getUserList();
        this.roleDialogFormVisible = false;
      });
    },
    editHandle(row) {
      let id = row.userId;
      this.flag = true;
      api.getOneUserInfo(id).then((res) => {
        this.editForm = res.data.data.result;
        this.merchants = parseInt(res.data.data.result.meId);
        this.store = parseInt(res.data.data.result.soeId);
        this.dialogVisible = true;
        this.titleName = this.editForm.username + "-" + "编辑";
      });
      this.userSoeId = row.soeId;
    },
    delHandle(id, username) {
      var ids = [];

      if (id) {
        ids.push(id);
      } else {
        this.multipleSelection.forEach((row) => {
          ids.push(row.id);
        });
      }

      api.deleteSysUser(ids, username).then((res) => {
        this.$notify({
          title: "信息提示",
          message: "操作成功",
          type: "success",
          position: "bottom-right",
          onClose: () => {
            this.getUserList(this.depaertId);
          },
        });
        this.getUserList(this.depaertId);
      });
    },
    handleClose() {
      this.resetForm("editForm");
    },

    repassHandle(id, username) {
      this.$confirm("将重置用户【" + username + "】的密码, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        api.repass(id).then((res) => {
          this.$message({
            showClose: true,
            message: "恭喜你，操作成功",
            type: "success",
            onClose: () => {},
          });
        });
      });
    },
    refresh() {
      this.searchForm = {};
      this.isTrue = true;
      this.getUserList();
      this.btndisable = true;
    },
  },
  created() {
    this.getUserInfo();
    this.getUserList();
    this.getlistAlliance();
    //this.getListMerchants(0);

    sys.getUserTree().then((res) => {
      this.treeData = res.data.data.result;
    });

    api.userinfo().then((res) => {
      this.ranks = res.data.data.result.ranks;
      if (this.ranks === 1) {
        this.alidisable = true;
      } else if (this.ranks === 2) {
        this.alidisable = true;
        this.merdisable = true;
      }
    });
  },
  data() {
    const checkPhone = (rule, value, cb) => {
      const reg = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (reg.test(value)) {
        cb();
      } else {
        cb(new Error("手机号格式有误"));
      }
    };
    const checkEmail = (rule, value, cb) => {
      const reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
      if (reg.test(value)) {
        cb();
      } else {
        cb(new Error("邮箱格式错误"));
      }
    };
    const checkUsername = (rule, value, cb) => {
      const reg = /^[A-Za-z0-9]{1,8}$/;
      if (reg.test(value)) {
        if (!this.flag) {
          //新增时校验
          api
            .verifyUsername({
              userName: value,
            })
            .then((res) => {
              if (res.data.data.result > 0) {
                cb(new Error("登录名已存在，请重新输入"));
              } else {
                cb();
              }
            });
        } else {
          cb();
        }
      } else {
        if (!this.flag) {
          cb(new Error("请输入字母或数字组成的1-8位登录名"));
        } else {
          //修改时不校验用户名
          cb();
        }
      }
    };
    return {
      //初次加载
      isTrue: true,
      radio: 1,
      searchForm: {},
      consulServices: [],
      value: 0,
      total: 0,
      size: 10,
      current: 1,
      dialogVisible: false,
      adjustment: false,
      expandOnClickNode: true,
      editForm: {
        alId: "",
        meId: "",
        soeId: "",
        userId: "",
        username: "",
        state: 1,
        sex: 0,
        phone: "",
        name: "",
      },
      adjustmentForm: {},
      tableData: [],
      alIdOptions: [], //联盟选择框
      meIdOptions: [], //商户选择框
      soeIdOptions: [], //所属门店
      flag: false, //用户编辑状态
      treeData: [], //所属商盟树
      searchId: "", //查询的id
      typeid: "", //级别类型
      depInput: "",
      slchange: 0,
      titleName: "新增用户",
      titleRole: "",
      editFormRules: {
        username: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
          { validator: checkUsername, trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { validator: checkEmail, trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: "1[3|4|5|7|8][0-9]{9}$",
            message: "请输入正确手机号",
            trigger: "blur",
          },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        state: [{ required: true, message: "请选择状态", trigger: "blur" }],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
      },
      defaultProps: {
        children: "children",
        label: "rname",
      },
      treeProps: {
        children: "children",
        label: "label",
      },
      roleForm: {},
      roleTreeData: [],
      multipleSelection: [],
      checkStrictly: true,
      roleDialogFormVisible: false,
      merchants: "",
      store: "",
      mervalue: "",
      alivalue: "",
      merdisable: false,
      alidisable: false,
      soedisable: false,
      ranks: "",
      btndisable: true,
      userSoeId: "",
    };
  },
};
</script>
<style scoped lang="scss">
.user_content {
  width: 100%;
  height: 87vh;
  .left {
    margin-left: 20px;
    padding-top: 20px;
    border-radius: 5px;
    height: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    overflow: auto;
  }
  .right {
    margin-left: 30px;
    padding-top: 20px;
    border-radius: 5px;
    height: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    overflow: auto;
  }
}
.el-pagination {
  float: right;
  margin-top: 22px;
}
// .main-select-el-tree .el-tree-node .is-current > .el-tree-node__content {
//   font-weight: bold;
//   color: #409eff;
// }
// .main-select-el-tree .el-tree-node.is-current > .el-tree-node__content {
//   font-weight: bold;
//   color: #409eff;
// }
</style>
<style lang="scss">
.tree-line {
  .el-tree-node {
    position: relative;
    padding-left: 16px; // 缩进量
  }
  .el-tree-node__children {
    padding-left: 16px; // 缩进量
  }

  // 竖线
  .el-tree-node::before {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    left: -3px;
    top: -26px;
    border-width: 1px;
    border-left: 1px dashed #52627c;
  }
  // 当前层最后一个节点的竖线高度固定
  .el-tree-node:last-child::before {
    height: 38px; // 可以自己调节到合适数值
  }

  // 横线
  .el-tree-node::after {
    content: "";
    width: 24px;
    height: 20px;
    position: absolute;
    left: -3px;
    top: 12px;
    border-width: 1px;
    border-top: 1px dashed #52627c;
  }

  // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
  & > .el-tree-node::after {
    border-top: none;
  }
  & > .el-tree-node::before {
    border-left: none;
  }

  // 展开关闭的icon
  .el-tree-node__expand-icon {
    font-size: 16px;
    // 叶子节点（无子节点）
    &.is-leaf {
      color: transparent;
      //display: none; // 也可以去掉
    }
  }
}
.user-left-tree .el-tree > .el-tree-node {
  display: inline-block;
  min-width: 100%;
  padding-right: 10px;
}
</style>
